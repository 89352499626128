export default [
  {
    title: 'Tablero de Indicadores',
    route: 'rt-board-main',
    icon: 'PieChartIcon',
    resource: 'rt-board-main',
  },
  {
    header: 'Monitoreo y Prevención',
    resource: 'rt-ppptcd',
  },
  {
    title: 'Casos',
    route: 'rt-ppptcd-cases',
    icon: 'FileTextIcon',
    resource: 'rt-ppptcd-cases',
  },
  {
    title: 'Lugares de Riesgo',
    route: 'rt-ppptcd-risk-place',
    icon: 'FlagIcon',
    resource: 'rt-ppptcd-risk-place',
  },
  {
    title: 'Instituciones Educativas',
    route: 'rt-ppptcd-educational-institution',
    icon: 'HomeIcon',
    resource: 'rt-ppptcd-educational-institution',
  },
  {
    title: 'Aliados Estratégicos',
    route: 'rt-ppptcd-institution',
    icon: 'UsersIcon',
    resource: 'rt-ppptcd-institution',
  },
  {
    header: 'Configuración',
    resource: 'rt-setting',
  },
  {
    title: 'Tipo de Instituciones',
    route: 'rt-setting-type-institution',
    icon: 'ListIcon',
    resource: 'rt-setting-type-institution',
  },
  {
    title: 'Tipo de Lugares de Riesgo',
    route: 'rt-setting-type-risk-place',
    icon: 'LayoutIcon',
    resource: 'rt-setting-type-risk-place',
  },
  /* {
    header: 'COMERCIAL',
    resource: 'rt-comercial',
  },
  {
    title: 'Tablero de Indicadores',
    route: 'rt-comercial-tablero-gerencia',
    icon: 'PieChartIcon',
    resource: 'rt-comercial-tablero-gerencia',
  },
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
    ],
  },
  {
    header: 'Charts & Mapsasdasd',
  },
  {
    title: 'Charts',
    icon: 'PieChartIcon',
    tag: '3',
    tagVariant: 'light-danger',
    children: [
      {
        title: 'Apex Chart',
        route: 'charts-apex-chart',
      },
      {
        title: 'Chartjs',
        route: 'charts-chartjs',
      },
      {
        title: 'Echart',
        route: 'charts-echart',
      },
    ],
  },
  {
    title: 'Leaflet',
    route: 'maps-leaflet',
    icon: 'MapIcon',
  },
  {
    title: 'Punto de Venta',
    route: 'rt-comercial-puntoventa',
    icon: 'DollarSignIcon',
    resource: 'rt-comercial-puntoventa',
  },
  {
    title: 'Clientes',
    route: 'rt-comercial-clientes',
    icon: 'UsersIcon',
    resource: 'rt-comercial-clientes',
  },
  {
    title: 'Productos',
    route: 'rt-comercial-productos',
    icon: 'ShoppingBagIcon',
    resource: 'rt-comercial-productos',
  },
  {
    title: 'Guias',
    route: 'rt-comercial-guiaremision',
    icon: 'ShoppingBagIcon',
    resource: 'rt-comercial-guiaremision',
  },
  {
    title: 'Compras',
    route: 'rt-comercial-compra',
    icon: 'ShoppingBagIcon',
    resource: 'rt-comercial-compra',
  },
  {
    header: 'RECURSOS HUMANOS',
    resource: 'rt-rrhh',
  },
  {
    title: 'Trabajadores',
    route: 'rt-rrhh-trabajadores',
    icon: 'UserCheckIcon',
    resource: 'rt-rrhh-trabajadores',
  },
  {
    header: 'CONTROL VIGILANCIA',
    resource: 'rt-viligancia',
  },
  {
    title: 'Ingreso / Salida',
    route: 'rt-viligancia-is',
    icon: 'LockIcon',
    resource: 'rt-viligancia-is',
  },
  {
    header: 'INVENTARIO',
    resource: 'rt-inventario',
  },
  {
    title: 'Flota',
    route: 'rt-inventario-flota',
    icon: 'TruckIcon',
    resource: 'rt-inventario-flota',
  },
  {
    header: 'Catálogos',
    resource: 'rt-catalogo',
  },
  {
    title: 'Presentacion',
    route: 'rt-catalogo-presentacion',
    icon: 'CheckCircleIcon',
    resource: 'rt-catalogo-presentacion',
  },
  {
    title: 'Marca',
    route: 'rt-catalogo-marca',
    icon: 'TagIcon',
    resource: 'rt-catalogo-marca',
  },
  {
    title: 'Tipo de Flota',
    route: 'rt-catalogo-tipoflota',
    icon: 'TruckIcon',
    resource: 'rt-catalogo-tipoflota',
  },
  {
    title: 'Empresa',
    route: 'rt-catalogo-empresa',
    icon: 'BriefcaseIcon',
    resource: 'rt-catalogo-empresa',
  }, */
  {
    header: 'Seguridad',
    resource: 'rt-seguridad',
  },
  {
    title: 'Usuarios',
    route: 'rt-seguridad-usuarios',
    icon: 'UsersIcon',
    resource: 'rt-seguridad-usuarios',
  },
  {
    title: 'Perfiles',
    route: 'rt-seguridad-perfiles',
    icon: 'AirplayIcon',
    resource: 'rt-seguridad-perfiles',
  },
  /* {
    title: 'Permisos',
    route: 'rt-seguridad-permisos',
    icon: 'UnlockIcon',
    resource: 'rt-seguridad-permisos',
  }, */
]
